import * as React from "react";
import Moment from "moment";
import "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import "./DateTime.css"

type Accuracy = 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';

const format = (accuracy: Accuracy) => {

    if (accuracy === 'millisecond')
        return 'DD-MMM-YYYY HH:mm:ss.SSS';

    if (accuracy === 'second')
        return 'DD-MMM-YYYY HH:mm:ss';

    if (accuracy === 'minute')
        return 'DD-MMM-YYYY HH:mm';

    if (accuracy === 'hour')
        return 'DD-MMM-YYYY HH';

    if (accuracy === 'day')
        return 'DD-MMM-YYYY';

    if (accuracy === 'month')
        return 'MMM-YYYY';

    return 'YYYY';
}

interface DateTimeProps {
    value: string,
    accuracy?: Accuracy;
    displayOffset?: boolean;
}

export const DateTime = ({ value, accuracy = 'second', displayOffset = true }: DateTimeProps) => {

    const offset = displayOffset
        ? ' (Z)'
        : '';
    const existingValue = Moment.parseZone(value);
    const formatted = existingValue.format(`${format(accuracy)}${offset}`);

    const userTimeZone = Moment.tz.guess();

    const adjustedDateTime = Moment.tz(existingValue, userTimeZone);
    const adjustedDateTimeString = adjustedDateTime.format(`${format(accuracy)}${offset}`);

    return (
        <div className="vt-tooltip">
            <span className="format format-date-time"><time>{formatted}</time>&nbsp;<FontAwesomeIcon className="text-muted" icon={faClock} /></span>
            <span className="tooltipText">
                Localized to you: {adjustedDateTimeString}
                <br />
                {adjustedDateTime ? adjustedDateTime.utc().fromNow() : null}
            </span>
        </div>
    );
};
