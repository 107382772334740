import * as React from 'react';
import * as Informed from 'informed';
import { FormGroup } from './FormGroup';
import { useUniqueId } from '../Hooks/UniqueId';
import { Validator, noValidation } from '../Validators/Validator';
import { validateMany } from '../Validators/Many';
import { validateRequired } from '../Validators/Required';
import strings from '../strings.json';

interface CheckboxProps {
    field: string;
    automationId?: string;
    initialValue?: boolean;
    label?: string;
    placeholder?: string;
    disabled?: boolean
    autoFocus?: boolean;
    required?: boolean;
    onValidate?: Validator;
    requiredMessage?: string;
}

export const Checkbox = ({ field, automationId, initialValue = false, label = '', placeholder = '', disabled = false, autoFocus = false, required = false, onValidate = noValidation, requiredMessage = strings.required }: CheckboxProps) => {

    const uniqueId = useUniqueId();
    const fieldState = Informed.useFieldState(field);
    const fieldApi = Informed.useFieldApi(field);

    const handleValidation = validateMany([
        validateRequired(required, requiredMessage),
        onValidate,
    ]);

    const errorMessage = fieldState.error || fieldApi.getError()
        ? (<div className="invalid-feedback" style={{ display: "block" }}>{fieldState.error || fieldApi.getError()}</div>)
        : null;

    const invalidClass = fieldState.error || fieldApi.getError()
        ? 'is-invalid'
        : '';

    const requiredClass = required
        ? 'is-required'
        : '';

    return (
        <FormGroup label={label} htmlFor={uniqueId}>
            <div className="pt-1">
                <div className="custom-control custom-checkbox">
                    <Informed.Checkbox id={uniqueId} className={`custom-control-input ${invalidClass} ${requiredClass}`} field={field} initialValue={initialValue} disabled={disabled} autoFocus={autoFocus} validate={handleValidation} data-automationid={automationId} />
                    <label className="custom-control-label" htmlFor={uniqueId}></label>
                    <label className="form-check-label" htmlFor={uniqueId}>{placeholder}</label>
                </div>
            </div>
            {errorMessage}
        </FormGroup>
    );
}
