import * as React from 'react';
import * as Informed from 'informed';
import { FormGroup } from './FormGroup';
import { useUniqueId } from '../Hooks/UniqueId';
import { noValidation } from '../Validators/Validator';
import { validateMany } from '../Validators/Many';
import { validateRequired } from '../Validators/Required';
import strings from '../strings.json';

interface SwitchProps {
    field: string;
    automationId?: string;
    initialValue?: boolean;
    label?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    onValidate?: (value: string, values: string[]) => string | undefined;
    requiredMessage?: string;
}

export const Switch = ({ field, automationId, initialValue = false, label = '', disabled = false, autoFocus = false, required = false, onValidate = noValidation, requiredMessage = strings.required }: SwitchProps) => {

    const uniqueId = useUniqueId();
    const fieldState = Informed.useFieldState(field);
    const fieldApi = Informed.useFieldApi(field);
    const initialStringValue = initialValue
        ? 'true'
        : undefined;

    const handleValidation = validateMany([
        validateRequired(required, requiredMessage),
        onValidate,
    ]);

    const errorMessage = fieldState.error || fieldApi.getError()
        ? (<div className="invalid-feedback" style={{ display: "block" }}>{fieldState.error || fieldApi.getError()}</div>)
        : null;

    const invalidClass = fieldState.error || fieldApi.getError()
        ? 'is-invalid'
        : null;

    const requiredClass = required
        ? 'is-required'
        : null;

    return (
        <FormGroup label={label} htmlFor={uniqueId}>
            <div className="pt-1">
                <div className="custom-control custom-switch">
                    <Informed.Checkbox id={uniqueId} className={`custom-control-input ${invalidClass} ${requiredClass}`} field={field} initialValue={initialStringValue} disabled={disabled} autoFocus={autoFocus} validate={handleValidation} data-automationid={automationId} />
                    <label className="custom-control-label" htmlFor={uniqueId}></label>
                </div>
                {errorMessage}
            </div>
        </FormGroup>
    );
}
