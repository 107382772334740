import * as React from 'react';

interface Unit {
    value: string;
    unit: 'B' | 'KB' | 'MB' | 'GB' | 'TB';
}

const format = (value: number): Unit => {
    if (value < 1024)
        return { value: value.toString(), unit: 'B' };

    value = value / 1024;

    if (value < 1024)
        return { value: value.toFixed(3), unit: 'KB' };

    value = value / 1024;

    if (value < 1024)
        return { value: value.toFixed(3), unit: 'MB' };

    value = value / 1024;

    if (value < 1024)
        return { value: value.toFixed(3), unit: 'GB' };

    value = value / 1024;

    return { value: value.toFixed(3), unit: 'TB' };
}

interface BytesProps {
    value: number;
    measurment?: '' | '/s';
}

export const Bytes = ({ value, measurment = '' }: BytesProps) => {

    const formatted = format(value);

    return <span className="format format-bytes">{formatted.value}&nbsp;<span className="text-muted text-weight-bold">{formatted.unit}{measurment}</span></span>;
}
