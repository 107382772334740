import { useState, useEffect } from 'react';

const root = "/api/v1"

type Status = 'pending' | 'fetching' | 'success' | 'error';

export interface About {
    service: string;
    description: string;
    version: string;
    oAuthAddress: string;
    oAuthClientId: string;
    oAuthScopes: string;
    oAuthRedirect: string;
    deviceLinkAddress: string;
    installerWin64Address: string;
}

const defaultAbout: About = {
    service: '',
    description: '',
    version: '',
    oAuthAddress: '',
    oAuthClientId: '',
    oAuthScopes: '',
    oAuthRedirect: '',
    deviceLinkAddress: '',
    installerWin64Address: '',
}

export const useGetAbout = (): [() => void, Status, About] => {
    const [response, setResponse] = useState<About>(defaultAbout);
    const [status, setStatus] = useState<Status>('pending');

    const init = () => setStatus('fetching');

    useEffect(() => {
        if (status !== 'fetching')
            return;

        fetch(`${root}/about`)
            .then(response => response.json())
            .then(json => setResponse(json))
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    }, [status]);

    return [init, status, response];
}

interface Country {
    id: string;
    name: string;
}

export const useGetCountries = (): [() => void, Status, Country[]] => {
    const [response, setResponse] = useState<Country[]>([]);
    const [status, setStatus] = useState<Status>('pending');

    const init = () => setStatus('fetching');

    useEffect(() => {
        if (status !== 'fetching')
            return;

        fetch(`${root}/countries`)
            .then(response => response.json())
            .then(json => setResponse(json))
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    }, [status]);

    return [init, status, response];
}

export interface SiteSurvey {
    id?: string;
    label?: string;
    usersName?: string;
    usersJobTitle?: string;
    organization: string;
    address: string;
    siteNumber: string;
    siteName?: string;
    contactName: string;
    countryId: string;
    timeZoneId: string;
    operatingSystem: string;
    operatingSystemError?: string;
    architecture: string;
    architectureError?: string;
    framework: string;
    frameworkError?: string;
    browser: string;
    browserError?: string;
    uploadSpeed: number;
    uploadSpeedError?: string;
    downloadSpeed: number;
    downloadSpeedError?: string;
    ipAddress?: string;
    created?: string;
    antiVirusDetails?: string;
    domainAccess?: boolean;
    usbAccess?: boolean;
    noDriveEncryption?: boolean;
    additionalInformation?: string;
}

export const usePutSurvey = (): [(data: SiteSurvey) => void, Status, SiteSurvey | undefined] => {
    const [request, setRequest] = useState<SiteSurvey>();
    const [status, setStatus] = useState<Status>('pending');
    const [response, setResponse] = useState<SiteSurvey>();

    const init = (data: SiteSurvey) => {
        setRequest(data);
        setStatus('fetching');
    }

    useEffect(() => {
        if (status !== 'fetching')
            return;

        fetch(`${root}/surveys`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then(response => response.json())
            .then(json => setResponse(json))
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    }, [status]);

    return [init, status, response];
}

export const usePostSurveyResults = (): [(data: SiteSurvey) => void, Status, SiteSurvey | undefined] => {
    const [request, setRequest] = useState<SiteSurvey>();
    const [status, setStatus] = useState<Status>('pending');
    const [response, setResponse] = useState<SiteSurvey>();

    const init = (data: SiteSurvey) => {
        setRequest(data);
        setStatus('fetching');
    }

    useEffect(() => {
        if (status !== 'fetching'
            || !request)
            return;

        fetch(`${root}/surveys/${request.id}/results`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then(response => response.json())
            .then(json => setResponse(json))
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    }, [status]);

    return [init, status, response];
}

export const useUploadTest = (): [() => void, Status, number] => {
    const [status, setStatus] = useState<Status>('pending');
    const [response, setResponse] = useState<number>(0);

    const init = () => setStatus('fetching');

    useEffect(() => {
        if (status !== 'fetching')
            return;

        const size = 20971520;
        const buffer = new Blob([new ArrayBuffer(size)], { type: 'application/octet-stream' });
        const start = (new Date()).getTime();

        fetch(`${root}/load`, {
            method: 'PUT',
            body: buffer,
        })
            .then(() => {
                const end = (new Date()).getTime();
                const speed = ((buffer.size * 8) / ((end - start) / 1000));

                setResponse(speed);
            })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    }, [status]);

    return [init, status, response];
}

export const useDownloadTest = (): [() => void, Status, number] => {
    const [status, setStatus] = useState<Status>('pending');
    const [response, setResponse] = useState<number>(0);

    const init = () => setStatus('fetching');

    useEffect(() => {
        if (status !== 'fetching')
            return;

        const start = (new Date()).getTime();

        fetch(`${root}/load`)
            .then(response => response.blob())
            .then(blob => {
                const end = (new Date()).getTime();
                const speed = ((blob.size * 8) / ((end - start) / 1000));

                setResponse(speed);
            })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    }, [status]);

    return [init, status, response];
}
