import * as React from 'react';
import { TroubleshootStepProps } from './Troubleshoot';
import { Badge, Bits } from '@vitalograph/web-ui';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface Badgeprops {
    error?: string;
}

const ErrorBadge = ({ error }: Badgeprops) => {
    const style = error
        ? 'danger'
        : 'success';

    const value = error
        ? 'Fail'
        : 'Pass';

    return <Badge style={style}>{value}</Badge>
}

const WarningBadge = ({ error }: Badgeprops) => {
    const style = error
        ? 'warning'
        : 'success';

    const value = error
        ? 'Warning'
        : 'Pass';

    return <Badge style={style}>{value}</Badge>
}

export const ResultsStep = ({ visible, details, isStudy }: TroubleshootStepProps) => {
    if (!visible)
        return null;

    const { id, organization, address, siteNumber, siteName, countryId, contactName, timeZoneId, ipAddress, operatingSystem, operatingSystemError, architecture, architectureError, framework, frameworkError, browser, browserError, uploadSpeed, uploadSpeedError, downloadSpeed, downloadSpeedError, antiVirusDetails, additionalInformation } = details;

    return (
        <>
            <div className="card-body">
                <p>The following information has been collected and used to determine any issues with your setup.</p>
                <table className="table">
                    <tr>
                        <th>Reference</th>
                        <td>{id ? id.toUpperCase() : ''}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>Organization</th>
                        <td>{organization}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>{address}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>Site Number</th>
                        <td>{siteNumber}</td>
                        <td></td>
                    </tr>
                    {isStudy ? <tr>
                        <th>Site Name</th>
                        <td>{siteName}</td>
                        <td></td>
                    </tr> : null}
                    <tr>
                        <th>Country</th>
                        <td>{countryId}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>Primary Contact</th>
                        <td>{contactName}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>Time Zone</th>
                        <td>{timeZoneId}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>IP Address</th>
                        <td>{ipAddress}</td>
                        <td></td>
                    </tr>
                    {isStudy ? <tr>
                        <th>Anti-virus Details</th>
                        <td>{antiVirusDetails}</td>
                        <td></td>
                    </tr> : null}
                    {isStudy ? <tr>
                        <th>Domain Access</th>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td></td>
                    </tr> : null}
                    {isStudy ? <tr>
                        <th>USB Access</th>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td></td>
                    </tr> : null}
                    {isStudy ? <tr>
                        <th>No external drive encryption software used</th>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td></td>
                    </tr> : null}
                    <tr>
                        <th>Operating System</th>
                        <td>{operatingSystem}</td>
                        <td><ErrorBadge error={operatingSystemError} /></td>
                    </tr>
                    <tr>
                        <th>Architecture</th>
                        <td>{architecture}</td>
                        <td><ErrorBadge error={architectureError} /></td>
                    </tr>
                    <tr>
                        <th>Framework</th>
                        <td>{framework}</td>
                        <td><ErrorBadge error={frameworkError} /></td>
                    </tr>
                    <tr>
                        <th>Browser</th>
                        <td>{browser}</td>
                        <td><ErrorBadge error={browserError} /></td>
                    </tr>
                    <tr>
                        <th>Upload Speed *</th>
                        <td><Bits value={uploadSpeed} measurment="/s" /></td>
                        <td><WarningBadge error={uploadSpeedError} /></td>
                    </tr>
                    <tr>
                        <th>Download Speed *</th>
                        <td><Bits value={downloadSpeed} measurment="/s" /></td>
                        <td><WarningBadge error={downloadSpeedError} /></td>
                    </tr>
                    {isStudy ? <tr>
                        <th>Additional Information</th>
                        <td>{additionalInformation}</td>
                        <td></td>
                    </tr> : null}
                    <tr>
                        <td className="text-muted" colSpan={3}>* Transfer speeds are to Vitalograph servers and may be different to other transfer speed tests.</td>
                    </tr>
                </table>
            </div>
            <div className="card-footer text-right">
                <Link className="btn btn-success" to="/">Finish</Link>
            </div>
        </>
    );
}
