import * as React from 'react';
import * as Informed from 'informed';
import { FormGroup } from './FormGroup';
import { useUniqueId } from '../Hooks/UniqueId';
import { Validator, noValidation } from '../Validators/Validator';
import { validateMany } from '../Validators/Many';
import { validateRequired } from '../Validators/Required';
import strings from '../strings.json';

interface RangeProps {
    field: string;
    min: number;
    max: number;
    automationId?: string;
    step?: number;
    initialValue?: number;
    label?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    onValidate?: Validator;
    requiredMessage?: string;
}

export const Range = ({ field, min, max, automationId, step = 1, initialValue, label = '', disabled = false, autoFocus = false, required = false, onValidate = noValidation, requiredMessage = strings.required }: RangeProps) => {

    const uniqueId = useUniqueId();
    const fieldState = Informed.useFieldState(field);
    const fieldApi = Informed.useFieldApi(field);
    const initialStringValue = '' + initialValue;

    const handleValidation = validateMany([
        validateRequired(required, requiredMessage),
        onValidate,
    ]);

    const errorMessage = fieldState.error || fieldApi.getError()
        ? (<div className="invalid-feedback" style={{ display: "block" }}>{fieldState.error || fieldApi.getError()}</div>)
        : null;

    const invalidClass = fieldState.error || fieldApi.getError()
        ? 'is-invalid'
        : '';

    const requiredClass = required
        ? 'is-required'
        : '';

    return (
        <FormGroup label={label} htmlFor={uniqueId}>
            <div className="pt-1">
                <Informed.Text id={uniqueId} className={`custom-range ${invalidClass} ${requiredClass}`} field={field} type="range" min={min} max={max} step={step} initialValue={initialStringValue} disabled={disabled} autoFocus={autoFocus} validate={handleValidation} autoComplete="off" data-automationid={automationId} />
                {errorMessage}
            </div>
        </FormGroup>
    );
}
