import * as React from "react";
import Moment from "moment";
import { ODataDirection, useODataQuery } from "./ODataQuery";
import { ODataFilterHeader } from "./ODataTable";
import { Modal } from "../Modal";
import { DatePicker } from "../DatePicker";

interface ODataDateMatchSortHeaderProps {
    title: string;
    field: string;
    sortField?: string;
}

export const ODataDateMatchSortHeader = ({ title, field, sortField = null }: ODataDateMatchSortHeaderProps) => {

    const [display, setDisplay] = React.useState(false);
    const { filters, ordering, applyOrdering } = useODataQuery();
    const [ordered, setOrdered] = React.useState<ODataDirection>();
    const [filtered, setFiltered] = React.useState(false);
    sortField = sortField ?? field;

    React.useEffect(() => {
        const filtered = filters.some(f => f.field === field && (f.condition === 'ge' || f.condition === 'le'));
        setFiltered(filtered);
    }, [filters]);

    React.useEffect(() => {

        const ordered = ordering.find(o => o.field === sortField);
        setOrdered(ordered
            ? ordered.condition
            : null);
    }, [ordering]);

    const handleSort = () => {
        let direction: ODataDirection = 'asc';

        if (ordered === 'asc')
            direction = 'desc';

        applyOrdering([{ field: sortField, condition: direction }]);
    }

    const handleFilter = () => {
        setDisplay(true);
    }

    const modal = display
        ? (
            <ODataDateMatchFilterModal title={title} field={field} onClose={() => setDisplay(false)} />
        )
        : null;

    return (
        <>
            <ODataFilterHeader filtered={filtered} sorted={ordered} onFilter={handleFilter} onSort={handleSort}>{title}</ODataFilterHeader>
            {modal}
        </>
    );
}

interface ODataDateMatchFilterModalProps {
    title: string;
    field: string;
    onClose: () => void;
}

export const ODataDateMatchFilterModal = ({ title, field, onClose }: ODataDateMatchFilterModalProps) => {
    const valueFormat = 'YYYY-MM-DD';

    const [valueFrom, setValueFrom] = React.useState<string>();
    const [valueTo, setValueTo] = React.useState<string>();
    const [fromError, setFromError] = React.useState<string | undefined>();
    const [toError, setToError] = React.useState<string | undefined>();
    const { filters, applyFilters, paging, applyPaging } = useODataQuery();

    React.useEffect(() => {

        const currentValueFrom = filters
            .filter(f => f.field === field && f.condition === 'ge')
            .flatMap(f => f.values);

        setValueFrom(currentValueFrom.length > 0 ? currentValueFrom[0] : Moment().subtract(1, 'months').format(valueFormat));

        const currentValueTo = filters
            .filter(f => f.field === field && f.condition === 'le')
            .flatMap(f => f.values);

        setValueTo(currentValueTo.length > 0 ? currentValueTo[0] : Moment().format(valueFormat));
    }, []);



    const handleApply = () => {
        setFromError(undefined);
        setToError(undefined);

        if (valueFrom === "") {
            setFromError("A value is required");
            return;
        }

        if (valueTo === "") {
            setToError("A value is required");
            return;
        }

        let newFilters = filters.filter(f => f.field !== field);

        newFilters = [
            ...filters,
            {
                field: field,
                condition: 'ge',
                values: [valueFrom],
                isCollection: false,
            }, {
                field: field,
                condition: 'le',
                values: [valueTo],
                isCollection: false,
            }
        ];

        applyPaging({ ...paging, page: 1 });
        applyFilters(newFilters);
        onClose();
    }

    const handleClear = () => {

        applyPaging({ ...paging, page: 1 });
        applyFilters(filters.filter(f => f.field !== field));
        onClose();
    }

    const fromErrorMessage = fromError
        ? (<div className="invalid-feedback" style={{ display: "block" }}>{fromError}</div>)
        : null;

    const toErrorMessage = toError
        ? (<div className="invalid-feedback" style={{ display: "block" }}>{toError}</div>)
        : null;

    const footer = (
        <div className="modal-footer">
            <button className="btn btn-primary" onClick={handleApply}>OK</button>
            <button className="btn btn-danger" onClick={handleClear}>Clear</button>
        </div>
    );

    return (
        <Modal header={`${title} Filter`} footer={footer}>
            <div className="row">
                <div className='col-md-6 form-group'>
                    <label>From</label>
                    <DatePicker onChange={(value) => setValueFrom(value)} value={valueFrom} className='form-control' placeholder="DD-MM-YYYY" required />
                    {fromErrorMessage}
                </div>
                <div className='col-md-6 form-group'>
                    <label>To</label>
                    <DatePicker onChange={(value) => setValueTo(value)} value={valueTo} className='form-control' placeholder="DD-MM-YYYY" required />
                    {toErrorMessage}
                </div>

            </div>
            
        </Modal>
    );
};
