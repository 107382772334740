import * as React from 'react';
import { useState, useEffect } from 'react';
import { TroubleshootStepProps, TroubleshootStep } from './Troubleshoot';
import { Button, Text, Checkbox, FormGroup, TextArea } from '@vitalograph/web-ui';
import { Form, useFormApi } from 'informed';
import { useGetCountries } from '../../Api/DashboardApi';

const TechnicalDetailsStepForm = ({ visible, details, onChangeStep, onDetailsChange, isStudy }: TroubleshootStepProps) => {
    if (!visible)
        return null;

    const { antiVirusDetails, domainAccess, usbAccess, noDriveEncryption } = details;
    const form = useFormApi();


    const handleBackClick = () => {
        onDetailsChange({ ...details, ...form.getState().values });
        onChangeStep(TroubleshootStep.UserDetails);
    }
    const handleNextClick = () => {
        onDetailsChange({ ...details, ...form.getState().values });
        form.validate();

        if (form.getState().invalid)
            return;

        onChangeStep(TroubleshootStep.SystemDetails);
    }

    const validateForCheck = (v: string | string[] | boolean | undefined) => v !== true ? "Please confirm to continue" : undefined

    return (
        <>
            <div className="card-body">
                
                <Text field="antiVirusDetails" label="Anti-Virus Software Installed" required initialValue={antiVirusDetails} autoFocus={true} />
                <FormGroup>
                    <p className="mb-1">
                    <i>Note, some installations of Antivirus software, e.g. McAfee, have been shown to trigger a ‘false positive’ alert for completed recording files. This report may prevent data upload to Vitalograph servers. This false alert can be prevented by setting an exception for the file name: ‘24*.wav’,
                            and also setting the antivirus software to ‘prompt for action if a threat is detected’. Vitalograph support (support@vitalograph.co.uk) can assist with applying this fix.</i>
                    </p>
                </FormGroup>
                <Checkbox field="domainAccess" label="Access to domains" onValidate={validateForCheck} required initialValue={domainAccess} placeholder="Access to domains *.vitalograph.com and *.vitalograph-clinical.com are unrestricted" />
                <Checkbox field="usbAccess" label="USB accessible" onValidate={validateForCheck} required initialValue={usbAccess} placeholder="USB ports are accessible on the computer so that the VitaloJAK can be connected" />
                <Checkbox field="noDriveEncryption" label="External Drive Encryption" onValidate={validateForCheck} initialValue={noDriveEncryption} placeholder="No external drive encryption software in use" />
                <TextArea field="additionalInformation" label="Additional Information" />
            </div>
            <div className="card-footer text-right">
                <Button style="primary" onClick={handleBackClick}>Back</Button>
                &nbsp;&nbsp;&nbsp;
                <Button style="success" onClick={handleNextClick}>Next</Button>
            </div>
        </>
    );
}

export const TechnicalDetailsStep = ({ visible, details, onChangeStep, onDetailsChange, isStudy }: TroubleshootStepProps) =>
    <Form>
        <TechnicalDetailsStepForm visible={visible} details={details} onChangeStep={onChangeStep} onDetailsChange={onDetailsChange} isStudy={isStudy} />
    </Form>
