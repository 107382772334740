import * as React from 'react';
import * as Informed from 'informed';
import { useUniqueId } from '../Hooks/UniqueId';
import { FormGroup } from './FormGroup';

interface TextAreaProps {
    field: string;
    automationId?: string;
    initialValue?: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
}

export const TextArea = ({ field, automationId, initialValue = '', label = '', placeholder = '', disabled = false, required = false, ...rest }: TextAreaProps) => {
    const uniqueId = useUniqueId();
    const fieldState = Informed.useFieldState(field);
    const fieldApi = Informed.useFieldApi(field);

    const errorMessage = fieldState.error || fieldApi.getError()
        ? (<div className="invalid-feedback">{fieldState.error || fieldApi.getError()}</div>)
        : null;

    const invalidClass = fieldState.error || fieldApi.getError()
        ? 'is-invalid'
        : '';

    const requiredClass = required
        ? 'is-required'
        : '';

    return (
        <FormGroup label={label} htmlFor={uniqueId}>
            <div>
                <Informed.TextArea {...rest} id={uniqueId} className={`form-control ${invalidClass} ${requiredClass}`} field={field} initialValue={initialValue} placeholder={placeholder} disabled={disabled} autoComplete="off" data-automationid={automationId} />
                {errorMessage}
            </div>
        </FormGroup>
    );
}
