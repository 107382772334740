import * as React from 'react';
import { useState, createContext, useContext, ReactNode, useEffect } from 'react';

const DefaultPageSize = 10;

type Condition = 'equal' | 'notEqual' | 'greaterThan' | 'greaterThanOrEqual' | 'lessThan' | 'lessThanOrEqual';

export type Direction = 'ascending' | 'descending';

interface Filter {
    member: string;
    condition: Condition;
    values: any[];
}

interface QueryState {
    provider: string;
    fields: string[];
    distinct: boolean;
    filters: Filter[];
    orderBy: string;
    orderDirection: Direction;
    page: number;
    pageSize: number;
    pageTotal: number;
    count: number;
    data: any[];
    fetching: boolean | undefined;
    refresh: () => void;
    filter: (filters: Filter[]) => void;
    order: (member: string, direction: Direction) => void;
    changePage: (page: number) => void;
}

const QueryContext = createContext<QueryState>({
    provider: '',
    fields: [],
    distinct: false,
    filters: [],
    orderBy: '',
    orderDirection: 'ascending',
    page: 0,
    pageSize: 0,
    pageTotal: 0,
    count: 0,
    data: [],
    fetching: undefined,
    refresh: () => { },
    filter: () => { },
    order: () => { },
    changePage: () => { },
});

const QueryProvider = QueryContext.Provider;

export const useQuery = () => useContext(QueryContext);

interface QueryProps {
    provider: string;
    fields: string[];
    distinct?: boolean;
    initialFilters?: Filter[];
    initialOrderBy: string;
    initialOrderDirection?: Direction;
    initialPage?: number;
    pageSize?: number;
    children?: ReactNode;
}

export const Query = ({ provider, fields, distinct = false, initialFilters = [], initialOrderBy, initialOrderDirection = 'ascending', initialPage = 1, pageSize = DefaultPageSize, children }: QueryProps) => {

    const [filters, setFilters] = useState(initialFilters);
    const [orderBy, setOrderBy] = useState(initialOrderBy);
    const [orderDirection, setOrderDirection] = useState(initialOrderDirection);
    const [page, setPage] = useState(initialPage);
    const [pageTotal, setPageTotal] = useState(0);
    const [count, setCount] = useState(0);
    const [data, setData] = useState<any[]>([]);
    const [fetching, setFetching] = useState<boolean>(undefined);

    useEffect(() => handleRefresh(), [fields, distinct, filters, orderBy, orderDirection, page, pageSize]);

    const handleRefresh = () => {
        setFetching(true);
        fetch(provider, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                members: fields,
                distinct: distinct,
                filters: filters,
                order: {
                    member: orderBy,
                    direction: orderDirection,
                },
                page: {
                    current: page,
                    length: pageSize,
                },
            }),
        })
            .then(response => response.json())
            .then(json => {
                setPageTotal(json.page.total);
                setCount(json.count);
                setData(json.data);
                setFetching(false);
            });
    }

    const handleFilter = (filters: Filter[]) => {
        setFilters(filters);
    }

    const handleOrder = (member: string, direction: Direction) => {

        setOrderBy(member);
        setOrderDirection(direction);
    }

    const handleChangePage = (newPage: number) => {

        newPage = newPage < 1 ? 1 : newPage;
        newPage = newPage > pageTotal ? pageTotal : newPage;

        setPage(newPage);
    }

    return (
        <QueryProvider value={{ provider: provider, fields: fields, distinct: distinct, filters: filters, orderBy: orderBy, orderDirection: orderDirection, page: page, pageSize: pageSize, pageTotal: pageTotal, count: count, data: data, fetching: fetching, refresh: handleRefresh, filter: handleFilter, order: handleOrder, changePage: handleChangePage }}>
            {children}
        </QueryProvider>
    );
}
